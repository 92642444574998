import LocaleSwitcher from '@/components/LocaleSwitcher/LocaleSwitcher';
import {
  Box,
  Container,
  Divider,
  Icon,
  Stack,
  VStack,
  chakra,
} from '@chakra-ui/react';
import {
  faEnvelope,
  faHome,
  faMapMarkerAlt,
  faPhone,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import footerLogo from '../../public/img/omapaja-logo-sininen-footer.png';

export function FooterBar() {
  const { t } = useTranslation();

  return (
    <Box as="footer" p={5}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing="24px">
        <Box w="100%">
          <VStack alignItems={'start'}>
            <Image src={footerLogo} alt="Omapaja" />
            <chakra.p fontStyle={'italic'}>
              <Icon as={() => <FontAwesomeIcon icon={faQuoteRight} />} />
              {t('footer.slogan')}
            </chakra.p>
          </VStack>
        </Box>

        <Box w="100%">
          <VStack alignItems={'start'}>
            <chakra.h4>
              <span>{t('footer.customerService')}</span>
            </chakra.h4>
            <chakra.p>{t('footer.schedule')}</chakra.p>
            <chakra.p style={{ whiteSpace: 'nowrap' }}>
              <Icon as={() => <FontAwesomeIcon icon={faEnvelope} />} />{' '}
              <Link href="mailto:asiakaspalvelu@omapaja.fi">
                asiakaspalvelu@omapaja.fi
              </Link>
            </chakra.p>
            <chakra.p>
              <Icon as={() => <FontAwesomeIcon icon={faPhone} />} />{' '}
              <Link href="tel:+358444100500">0444 100 500</Link>
            </chakra.p>
          </VStack>
        </Box>

        <Box w="100%">
          <VStack alignItems={'start'}>
            <chakra.h4>
              <span>{t('footer.contactInfo')}</span>
            </chakra.h4>
            <chakra.p>
              <strong>Omapaja Oy</strong>
            </chakra.p>
            <chakra.p style={{ whiteSpace: 'nowrap' }}>
              <Icon as={() => <FontAwesomeIcon icon={faHome} />} />
              &nbsp;
              <Link href="https://omapaja.fi/yhteystiedot">
                omapaja.fi/yhteystiedot
              </Link>
            </chakra.p>
            <chakra.p>
              <Icon as={() => <FontAwesomeIcon icon={faMapMarkerAlt} />} />
              &nbsp;Zatelliitintie 14, 90440, Kempele
            </chakra.p>
            <chakra.p>{t('footer.businessId')}</chakra.p>
          </VStack>
        </Box>

        <Box w="100%">
          <VStack alignItems={'start'}>
            <chakra.h4 style={{ whiteSpace: 'nowrap' }}>
              <span>{t('footer.serviceInfo')}</span>
            </chakra.h4>
            <chakra.p>
              &raquo;{' '}
              <Link href="https://omapaja.fi/kevytyrittajyys/ukk/">
                {t('footer.faq')}
              </Link>
            </chakra.p>
            <chakra.p>
              &raquo;{' '}
              <Link href="https://omapaja.fi/kevytyrittajyys/hinnasto">
                {t('footer.pricing')}
              </Link>
            </chakra.p>
            <chakra.p>
              &raquo;{' '}
              <Link href="https://omapaja.fi/tietosuojakaytanto">
                {t('footer.privacyPolicy')}
              </Link>
            </chakra.p>
          </VStack>
        </Box>
      </Stack>

      <Divider py={3} />
      <Container py={2} centerContent>
        <chakra.p>
          Copyright &copy; Omapaja Oy {new Date().getFullYear()}
        </chakra.p>
      </Container>

      <Container centerContent>
        <LocaleSwitcher />
      </Container>
    </Box>
  );
}
