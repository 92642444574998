import { AxiosResponse } from 'axios';
import axiosInstance from './middleware';
import { DEFAULT_APP_CLASSNAME } from '../utils/config';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type HttpRequestType =
  | 'arraybuffer'
  | 'blob'
  | 'document'
  | 'json'
  | 'text'
  | 'stream';

type ApiType<T> = {
  URL: string;
  method?: HttpMethod;
  headers?: Record<string, string>;
  body?: T;
  params?: Record<string, unknown>;
  queryParams?: Record<string, unknown>;
  responseType?: HttpRequestType;
  auth?: boolean;
};

export type TApiResponse<T> = Promise<AxiosResponse<T>>;

const makeApi = <T>({
  URL,
  method,
  headers: headerParams,
  body,
  params,
  queryParams,
  responseType,
  auth = true,
}: ApiType<T>) => {
  const config = {
    method,
    url: URL,
    params,
    queryParams,
    headers: {
      ...headerParams,
    },
    data: {
      ...(auth && { access_token: localStorage.getItem('token') }),
      identity_client: `${DEFAULT_APP_CLASSNAME};web;2.0`,
      ...body,
    },
    responseType,
  };
  return axiosInstance(config);
};

const POST = <T>({ URL, headers, body }: ApiType<T>) => {
  return makeApi<T>({
    URL: `${axiosInstance.getUri()}${URL}`,
    method: 'POST',
    headers,
    body,
  });
};

export { POST };
