import axios from 'axios';

const backendBaseUrl = process.env.NEXT_PUBLIC_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: backendBaseUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['accept-language'] = 'en-GB,en-US;q=0.9,en;q=0.8';
    config.headers['content-type'] = 'application/json';
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
