import { RolePreviewsOptions, RoleResponse } from '../../types/roles';
import { POST, TApiResponse } from '../api-service';

export const getRoleList = (
  options: RolePreviewsOptions = {}
): TApiResponse<RoleResponse> => {
  return POST({
    URL: 'rolelist',
    body: {
      ...options,
      version: 3,
      action: 'read',
      command: 'rolelist',
    },
  });
};
