'use client';

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { signIn, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FooterBar } from '../layout/FooterBar';
import { getRoleList } from '../api/roles/getRoleList';
import { getUserInfo } from '../api/users/getUserList';

function HomePage() {
  const { t } = useTranslation();

  const router = useRouter();
  const { status, data: session } = useSession();
  const [clientLoaded, setClientLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setClientLoaded(true);
  }, []);

  useEffect(() => {
    if (status === 'authenticated') {
      router.push('/dashboard');
    }
  });

  useEffect(() => {
    if (session?.user?.access_token && session?.user?.refresh_token) {
      localStorage.setItem('token', session.user.access_token);
      localStorage.setItem('refresh_token', session.user.refresh_token);
      const expiryDate =
        Math.round(new Date().getTime() / 1000) +
        (session.user.expires_in ?? 0);

      localStorage.setItem('token_expires_in', expiryDate?.toString());
    }
  }, [session]);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body = new URLSearchParams({
          username: values.username,
          password: values.password,
          grant_type: 'password',
          client_id: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID || '',
          client_secret: process.env.NEXT_PUBLIC_OAUTH_SECRET_ID || '',
        }).toString();

        const res = await fetch('https://oauth.honkio.com/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body,
        });

        if (!res.ok) {
          console.error('Failed to fetch token:', res.status, res.statusText);
          setIsLoading(false);
          return null; // Or handle accordingly
        }

        const user = await res.json();
        if (user.access_token) {
          const data = await getRoleList({
            query_all: false,
            query_info: true,
            role: null,
            access_token: user.access_token,
          });
          if (data?.data?.status === 'accept') {
            localStorage.setItem('roles', JSON.stringify(data?.data?.roles));
            localStorage.setItem('roleId', data?.data?.roles?.[0]?.id);
          }
          const userInfo = await getUserInfo({
            role: data?.data?.roles?.[0]?.id,
            access_token: user.access_token,
          });
          await signIn('credentials', {
            redirect: false,
            access_token: user.access_token,
            refresh_token: user.refresh_token,
            expires_in: user.expires_in,
          });

          if (userInfo?.data?.status === 'accept') {
            console.log(userInfo);

            localStorage.setItem('user', JSON.stringify(userInfo.data.user));
          }
          const expiryDate =
            Math.round(Date.now() / 1000) + (user.expires_in ?? 0);
          localStorage.setItem('token', user.access_token);
          localStorage.setItem('refresh_token', user.refresh_token);
          localStorage.setItem('token_expires_in', expiryDate.toString());

          router.push('/dashboard');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Error during fetch:', error);
        return null;
      }
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .email(t('login.emailInvalid'))
        .required(t('login.emailRequired')),
      password: Yup.string().required(t('login.passRequired')),
    }),
  });

  // Only render the component if it’s loaded on the client
  if (!clientLoaded) {
    return null; // Optionally, you can render a loading spinner here
  }

  return (
    <Flex minH="100vh" direction="column">
      <Center flexGrow={1}>
        <Image
          src="https://omapajaportal.tndev.iggo.fi/img/adminmart/login/login-image-2.png?1616571917"
          w="600"
          h="500"
          alt="omapaja"
        />
        <form onSubmit={formik.handleSubmit}>
          <Box color="black" p={5}>
            <Stack justify="center" align="center" spacing={5}>
              <Heading as="h1" fontSize="2xl" fontWeight="100">
                {t('home.loginTitle')}
              </Heading>
              <Text>{t('home.loginSubtitle')}</Text>

              <FormControl
                isInvalid={
                  !!formik.touched.username && !!formik.errors.username
                }
              >
                <FormLabel>{t('login.email')}</FormLabel>
                <Input type="email" {...formik.getFieldProps('username')} />
                <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={
                  !!formik.touched.password && !!formik.errors.password
                }
              >
                <FormLabel flex={2}>{t('login.password')}</FormLabel>
                <Input
                  flex={5}
                  type="password"
                  {...formik.getFieldProps('password')}
                />
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>

              <Button
                variant="solid"
                colorScheme="#182868;"
                size="sm"
                type="submit"
                display="flex"
                alignItems="center"
                gap={2}
              >
                {isLoading && <Spinner color="white" />}
                {t('home.loginBtn')}
              </Button>

              <Link href={'/register'}>
                <Box fontSize="xs" _hover={{ color: 'blue.400' }}>
                  {t('home.registrationLink')}
                </Box>
              </Link>

              <Link href={'/forgot-password'}>
                <Box fontSize="xs" _hover={{ color: 'blue.400' }}>
                  {t('home.forgotPassword')}?
                </Box>
              </Link>
            </Stack>
          </Box>
        </form>
      </Center>
      <Box w="100%" p={4}>
        <FooterBar />
      </Box>
    </Flex>
  );
}

export default HomePage;
