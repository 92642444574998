export const DEFAULT_APP_CLASSNAME = 'com.omapaja';
export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL ?? 'https://admin.genii-solutions.com/api/';
// Checks each <TOKEN_EXPIRATION_CHECK_INTERVAL> milliseconds if token is expired
export const TOKEN_EXPIRATION_CHECK_INTERVAL = 1 * 60 * 1000; // 1 minute;
// Refresh token <TOKEN_REFRESH_MARGIN> minutes before actual expiration
export const TOKEN_REFRESH_MARGIN = 7; // 7 minutes

export const QUERY_APPLICATION = '6728c1864cbf2396e4436974';
export const QUERY_MERCHANT = '6728c1864cbf2396e4436975';
